const initInvisibleRecaptcha = () => {
  let callback;

  window.onRecaptchaResponse = (response) => {
    callback(response);
    grecaptcha.reset();
  };

  window.getRecaptchaResponse = () => {
    grecaptcha.execute();
    return new Promise(resolve => { callback = resolve; });
  };

  $('form').on('submit', function (e) {
    e.preventDefault();
    getRecaptchaResponse().then(response => {
      $(this).find('[name="g-recaptcha-response"]').val(response);
      this.submit();
    });
  });
};

$(initInvisibleRecaptcha);
